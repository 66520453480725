<template>

	<div v-if="result">
		<div v-if="result === 'success'" class="box">
			<font-awesome-icon icon="check-circle" class="text-success icon" />
			<h1>{{ $t("registration-result.title") }}</h1>
			<h6>{{ $t("registration-result.desc") }}</h6>
			<div class="features mt-4">
				<RouterLink to="login" tag="a" class="btn btn-primary">
					<font-awesome-icon icon="sign-in-alt" />
					{{ $t("registration-result.go-to-login") }}
				</RouterLink>
			</div>
		</div>
		<div v-else class="box">
			<font-awesome-icon icon="exclamation-triangle" class="text-danger icon" />
			<h1>{{ $t("registration-result.error-title") }}</h1>
			<h6>{{ $t("registration-result.error-desc") }}</h6>
			<div class="features gap-4 mt-4">
				<RouterLink to="login" tag="a" class="btn btn-primary">
					<font-awesome-icon icon="sign-in-alt" />
					{{ $t("registration-result.go-to-login") }}
				</RouterLink>
				<RouterLink to="contact" tag="a" class="btn btn-dark">
					<font-awesome-icon icon="address-card" />
					{{ $t("registration-result.contact-support") }}
				</RouterLink>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'RegistrationResultView',
	components: {  },
	data() {
		return {
			result: null
		}
	},
	methods: {
		isMobile(){
			//console.log(navigator.userAgent)
			if(/Android/i.test(navigator.userAgent)){
				return true
			}
			return false
		},
		parseResult() {
			this.result = this.$route.query.v
		}
	},
	beforeMount() {
		this.parseResult()
	}

}

</script>

<style scoped>
.reg-res-card {
	box-shadow: 0 0 3px lightgrey;
}

.reg-res-login{
	margin-top: 2rem;
}


.box {
	margin-top: 3rem;
	text-align: center;
	width: 100%;
}
.icon {
	font-size: 5rem;
}
h1 {
	font-size: 2rem;
	margin-top: 1rem;
}

.features {
	display: flex;
	justify-content: center;
	padding: 0 1rem 0 0rem;
	flex-wrap: wrap;
}


</style>
